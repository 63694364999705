import React from 'react';
import logo from '../../images/logo.png';
import './navbar.css';
import LogoutButton from '../../users/LogoutButton';

export default function NavBar() {
  return (
    <>
      <div className="w-full text-xl flex">
        <nav className="fixed top-0 right-0 h-full border-l border-neutral bg-base-100 dark:bg-neutral transition-all duration-300 hover:w-64 w-20 group">
          <div className="flex flex-col items-center h-full p-4">
            <a href="/" className="flex flex-col items-center mb-8">
              <img src={logo} className="w-32" alt="Logo" />
              <span className="self-center text-white text-6xl font-semibold whitespace-nowrap text-neutral hidden group-hover:block">
                נאטורז
              </span>
            </a>
            <ul className="flex flex-col space-y-4 font-medium w-full">
              <li>
                <a
                  href="/patients"
                  className="flex items-center py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary dark:text-white dark:hover:bg-neutral w-full"
                >
                  <i className="fas fa-users"></i>
                  <span className="mr-3 hidden group-hover:block">מטופלים</span>
                </a>
              </li>
              <li>
                <a
                  href="/appointments"
                  className="flex items-center py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary dark:text-white dark:hover:bg-neutral w-full"
                >
                  <i className="fas fa-calendar-alt"></i>
                  <span className="mr-3 hidden group-hover:block">יומן</span>
                </a>
              </li>
              <li>
                <a
                  href="/accountements"
                  className="flex items-center py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary dark:text-white dark:hover:bg-neutral w-full"
                >
                  <i className="fas fa-file-invoice"></i>
                  <span className="mr-3 hidden group-hover:block">חשבונות</span>
                </a>
              </li>
              <li>
                <a
                  href="/mediacafiles"
                  className="flex items-center py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary dark:text-white dark:hover:bg-neutral w-full"
                >
                  <i className="fas fa-folder"></i>
                  <span className="mr-3 hidden group-hover:block">תיקים</span>
                </a>
              </li>
              <li>
                <a
                  href="/drugs"
                  className="flex items-center py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary dark:text-white dark:hover:bg-neutral w-full"
                >
                  <i className="fas fa-prescription-bottle-alt"></i>
                  <span className="mr-3 hidden group-hover:block">מרשמים</span>
                </a>
              </li>
              <li>
                <a
                  href="/chat"
                  className="flex items-center py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary dark:text-white dark:hover:bg-neutral w-full"
                >
                  <i className="fas fa-comments"></i>
                  <span className="mr-3 hidden group-hover:block">
                    CHAT GPT
                  </span>
                </a>
              </li>
            </ul>
            <div className="flex items-end mt-auto w-full">
              <ul className="w-full">
                <li className="w-full">
                  {' '}
                  <a href="/newpatient" className="w-full">
                    <button
                      type="button"
                      className="text-white bg-secondary text-2xl hover:bg-secondary-focus focus:ring-4 focus:outline-none focus:ring-secondary-focus font-medium rounded-lg text-sm px-4 py-2 text-center w-full"
                    >
                      <i className="fas fa-user-plus"></i>
                      <span className="mr-3 hidden group-hover:block">
                        הוספת מטופל
                      </span>
                    </button>
                  </a>
                </li>
                <li className="w-full">
                  <LogoutButton />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
