import React, { useState, useEffect } from 'react';
import axios from 'axios';
import backendUrl from '../../../utils/config/config';

const ImageAdd = ({ patient, setImageList, imageList }) => {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('');
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = async (e) => {
    e.preventDefault();
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add('border-blue-500'); // Change border color to indicate drag over
  };
  const dragEnterHandler = (e) => {
    e.currentTarget.classList.add('border-blue-500'); // Change border color to indicate drag over
  };
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('border-blue-500'); // Remove border color when leaving the drop zone
  };
  const dropHandler = async (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('border-blue-500'); // Remove border color when dropping the file
    const files = e.dataTransfer.files;
    setImage(files[0]);
    setImageName(files[0].name);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleImage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', image);
    formData.append('patient_id', patient.id);

    try {
      const response = await axios.post(`${backendUrl}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Image uploaded successfully:', response.data);
      setImageList([...imageList, response.data]);
    } catch (error) {
      alert('Error uploading image:', error);
    }
    setImage(null);
    setImageName('');
    setImagePreview(null);
  };

  return (
    <div className="flex max-w-32 max-h-36 flex-grow items-center justify-center  gap-4 w-32">
      <label
        onDragOver={dragOverHandler}
        onDragEnter={dragEnterHandler}
        onDragLeave={dragLeaveHandler}
        onDrop={dropHandler}
        htmlFor="dropzone-file"
        className="flex flex-col items-center
                justify-center max-h-36 w-full h-64 border-2 border-gray-300
                border-dashed rounded-lg cursor-pointer bg-gray-50
                dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100
                dark:border-gray-600 dark:hover:border-gray-500
                dark:hover:bg-gray-600"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            className="w-8 h-8 mb-4 text-white dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 16"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
            />
          </svg>
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Image Preview"
              className="max-w-36 max-h-36 object-cover rounded-lg"
            />
          ) : (
            <p className="mb-2 text-xl text-white dark:text-white">
              <span className="font-semibold">
                {imageName ? imageName : 'Click to upload'}
              </span>{' '}
              or drag and drop
            </p>
          )}
          <p className="text-xs text-white dark:text-white">
            SVG, PNG, JPG or GIF
          </p>
        </div>
        <input
          id="dropzone-file"
          type="file"
          className="hidden"
          name="pic"
          onChange={handleImageChange}
        />
      </label>
      <button
        className="text-white center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={handleImage}
      >
        העלאה
      </button>
    </div>
  );
};

export default ImageAdd;
