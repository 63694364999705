import React, { useState, useEffect, useRef } from 'react';
import backendUrl from '../../utils/config/config';
import axios from 'axios';

export default function PatientMedicalHostory({ patient }) {
  const ills_dic = {
    סוכרת: 'diabetes',
    'מחלת לב': 'heart_disease',
    'מום לב מלידה': 'congenital_heart_defect',
    'רשרוש בלב': 'heart_palpitations',
    'יתר לחץ דם': 'hypertension',
    אסטמה: 'asthma',
    'מחלות כליות': 'kidney_disease',
    'ניטה לדמם': 'incontinence',
    סרטן: 'cancer',
    אפילפסיה: 'epilepsy',
    'מחלת עצבים': 'neurological_disorder',
    'טיפול פסיכיאטרי': 'psychiatric_treatment',
    'מחלות פרקים': 'joint_diseases',
    'צהבת C/B/A': 'hepatitis',
    איידס: 'aids',
    עישון: 'smoking',
  };

  const [medical_history, setMedical_history] = useState({
    patient_id: patient.id,
    diabetes: false,
    heart_disease: false,
    congenital_heart_defect: false,
    heart_palpitations: false,
    hypertension: false,
    asthma: false,
    kidney_disease: false,
    incontinence: false,
    cancer: false,
    epilepsy: false,
    neurological_disorder: false,
    psychiatric_treatment: false,
    joint_diseases: false,
    hepatitis: false,
    aids: false,
    smoking: false,
    notes: '',
  });
  // const ills = [
  //   'סוכרת',
  //   'מחלת לב',
  //   'מום לב מלידה',
  //   'רשרוש בלב',
  //   'יתר לחץ דם',
  //   'אסטמה',
  //   'מחלות כליות',
  //   'ניטה לדמם',–≠–
  //   'סרטן',
  //   'אפילפסיה',
  //   'מחלת עצבים',
  //   'טיפול פסיכיאטרי',
  //   'מחלות פרקים',
  //   'צהבת C/B/A',
  //   'איידס',
  //   'עישון',
  // ];

  useEffect(() => {
    async function fetchData() {
      const response = await axios.post(`${backendUrl}/medical-history`, {
        patient_id: patient.id,
      });
      console.log(response.status);
    }
    fetchData();
    console.log(medical_history);
  }, []);

  const prevMedicalHistoryRef = useRef();
  useEffect(() => {
    // Update the previous medical history ref whenever the medical history state changes
    prevMedicalHistoryRef.current = medical_history;
  }, [medical_history]);

  useEffect(() => {
    return () => {
      async function updateBackend() {
        await axios.put(
          `${backendUrl}/medical-history/${patient.id}`,
          prevMedicalHistoryRef.current
        );
      }
      updateBackend();
    };
  }, [ills_dic]);

  const handleCheckboxChange = (key) => {
    const englishKey = ills_dic[key]; // Get the English key from the dictionary
    console.log(medical_history);
    setMedical_history((prevMedicalHistory) => ({
      ...prevMedicalHistory,
      [englishKey]: !prevMedicalHistory[englishKey], // Use the English key to update the state
    }));
  };

  return (
    <>
      <dl className="grid max-w-screen-xl grid-cols-4 gap-8 p-4 mx-auto text-white sm:grid-cols-3 xl:grid-cols-5 dark:text-white sm:p-8">
        {Object.keys(ills_dic).map((ill) => (
          <div key={ill} className="flex flex-col items-center justify-center">
            <dt className="mb-2 text-3xl font-extrabold">
              <label className="inline-flex items-center mb-5 cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={medical_history[ill]}
                  onChange={() => handleCheckboxChange(ill)}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after: after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </dt>
            <dd className="text-white dark:text-white">
              <strong>{ill}</strong>
            </dd>
          </div>
        ))}
        <div key={1} className="justify-center col-span-3">
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => handleCheckboxChange(e.value)}
          />
        </div>
      </dl>
    </>
  );
}
