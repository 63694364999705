import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas';
import backendUrl from '../../../utils/config/config';

const LegalAgreement = ({ onAgree }) => {
  const [agreement, setAgreement] = useState({});
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const sigCanvas = useRef({});
  const agreementName = 'dental-agreement';

  useEffect(() => {
    const fetchAgreement = async () => {
      try {
        const data = await axios.get(
          `${backendUrl}/agreements/${agreementName}`
        ); // Adjust the URL as per your server
        setAgreement(data.data);
        console.log(data.data);
      } catch (error) {
        console.error('Error fetching agreement', error);
      }
    };

    fetchAgreement();
  }, []);

  const clear = () => sigCanvas.current.clear();
  const trim = () => {
    setTrimmedDataURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    );
    setShowModal(true);
  };

  return (
    <div className="p-4 bg-gray-800 rounded-lg shadow-lg mx-auto">
      <h2 className="text-4xl text-white font-semibold mb-2">הצהרת בריאות</h2>
      <p className="text-white mb-4">{agreement.agreement}</p>
      <div className="mb-4 max-w-md">
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{
            className:
              'signatureCanvas bg-white w-full h-48 rounded-lg shadow-inner',
          }}
        />
        <div className="flex justify-between mt-2">
          <button
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-300"
            onClick={clear}
          >
            נקה
          </button>
          <button
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-green-300"
            onClick={trim}
          >
            שמור
          </button>
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-2xl font-semibold mb-2">חתימה</h3>
            {trimmedDataURL ? (
              <div className="mb-4">
                <img
                  src={trimmedDataURL}
                  alt="Signature"
                  className="w-full h-auto"
                />
              </div>
            ) : null}
            <button
              onClick={() => {
                onAgree(trimmedDataURL);
                setShowModal(false);
              }}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              מאשר
            </button>
            <button
              onClick={() => setShowModal(false)}
              className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-gray-300 ml-2"
            >
              סגור
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LegalAgreement;
