import React, { useState } from 'react';

function SaleCard({ setDiscount }) {
  const [d, setd] = useState(0); // State for the discount amount

  const applyDiscount = () => {
    setDiscount(d);
  };

  return (
    <div className="text-3xl font-medium text-white shadow-lg justify-center rounded-lg overflow-hidden mb-4 group  aspect-auto border rounded-3xl bg-gray-800 border-gray-700 shadow-gray-600/10 shadow-none m-2 flex-1 max-w-md">
      <div className="p-4">
        <h3 className=" font-bold mb-2 text-center text-white">
          שינוי סכום
        </h3>
        <div className="flex text-center items-center justify-center mb-4  font-bold">
          <label htmlFor="discount" className="text-center text-white">
            הנחה :
          </label>
        </div>

        <input
          type="number"
          id="discount"
          className="border px-0 w-full p-2 text-white rounded bg-gray-400 font-4xl text-center"
          value={d}
          onChange={(e) => setd(Number(e.target.value))}
        />
        <button
          className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={applyDiscount}
        >
          מימוש
        </button>
      </div>
    </div>
  );
}

export default SaleCard;
