import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import photo from '../images/chair2.jpg';
import backendUrl from '../utils/config/config';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${backendUrl}/login`, {
        username,
        password,
      });
      localStorage.setItem('token', response.data.token);
      setMessage('Login successful');
      navigate('/'); // Redirect to homepage after successful login
    } catch (error) {
      setMessage('Login failed');
    }
  };

  return (
    <div
      className="h-screen flex items-center justify-center"
      style={{
        backgroundImage: `url(${photo})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="">
        <div className="">
          <form onSubmit={handleSubmit} className="">
            <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
              <p className="mx-4 mb-0 text-center text-2xl font-bold dark:text-blue-800">
                התחברות
              </p>
            </div>

            <input
              type="text"
              placeholder="משתמש"
              size="lg"
              className="bg-gray-50 w-full border border-gray-300 m-4  text-2xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark: dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <input
              type="password"
              placeholder="סיסמה"
              className="bg-gray-50 w-full border border-gray-300 m-4  text-2xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark: dark:focus:ring-blue-500 dark:focus:border-blue-500"
              size="lg"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

         

            <div className="text-center lg:text-left">
              <button
                type="submit"
                className="inline-block rounded bg-primary px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-lg transition duration-150 ease-in-out hover:bg-primary-600 focus:outline-none"
              >
                התחברות
              </button>
              <p>{message}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default LoginForm;
