import React, { useState } from 'react';
import { GoogleGenerativeAI } from '@google/generative-ai';
import { Axios } from 'axios';
const genAI = new GoogleGenerativeAI('AIzaSyAMJMQ5AWeTIutO9TbcQBG17r3d2zfcdw4');
const model = genAI.getGenerativeModel({ model: 'gemini-pro' });

const ChatWindow = () => {
  const [search, setSearch] = useState('');
  const [aiResponse, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  async function aiRun() {
    setLoading(true);
    const prompt = ` ${search}`;
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();
    setResponse(text);
    setChatHistory([...chatHistory, { question: search, answer: text }]);
    setSearch('');
    setLoading(false);
  }

  const handleClick = () => {
    aiRun();
  };

  return (
    <div className="flex justify-center items-center text-2xl  h-screen bg-gray-900 mr-10">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full h-full max-w-full text-center overflow-y-auto">
        <h1 className="text-2xl font-semibold text-white mb-4">
          אפליקציית שיניים מבוססת AI!
        </h1>
        <p className="text-gray-400 mb-6">נבנה עם ❤️ בעזרת Google Gemini</p>

        <div className="flex mb-4">
          <input
            className="flex-grow p-2 border border-gray-600 rounded-l-md bg-gray-700 text-white focus:outline-none"
            placeholder="שאל שאלה על בריאות השיניים..."
            value={search}
            onChange={handleChangeSearch}
            onKeyDown={(e) => e.key === 'Enter' && handleClick()}
          />
          <button
            className="p-2 bg-green-600 text-white rounded-r-md hover:bg-green-700 transition-colors"
            onClick={handleClick}
            disabled={loading || !search}
          >
            חפש
          </button>
        </div>
        {loading && <p className="text-gray-400 mt-4">טוען ...</p>}

        <div className="bg-gray-700 p-4 rounded-md overflow-y-auto h-3/4">
          {chatHistory.map((chat, index) => (
            <div key={index} className="mb-4 ">
              <p className="text-green-400 text-left bg-gray-500">
                שאלה: {chat.question}
              </p>
              <p className="text-white text-right">
                {' '}
                <span className="text-right">תשובה: </span>
                <br /> {chat.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
