import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PatientMedicalHostory from '../PatientMedicalHistory';
import backendUrl from '../../../utils/config/config';
import LegalAgreement from './LegalAgreement';

const AddPatientFormDemo = () => {
  const [newPatient, setNewPatient] = useState({
    id: '',
    firstname: '',
    lastname: '',
    birthdate: '',
    email: '',
    phone: '',
    age: '',
    gender: '',
    isActive: true,
  });
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [mediacaHist, setmediacaHist] = useState(false);

  const handleAgreement = () => {
    setAgreed(true); // Set user agreement to true
  };
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    e.preventDefault();
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const changeHist = (e) => {
    e.preventDefault();
    setmediacaHist(!mediacaHist);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPatient({
      ...newPatient,
      [name]: value,
    });
    // Recalculate age if birthdate is changed
    if (name === 'birthdate') {
      const birthDate = new Date(value);
      const ageDiffMs = Date.now() - birthDate.getTime();
      const ageDate = new Date(ageDiffMs); // miliseconds from epoch
      const age = Math.abs(ageDate.getUTCFullYear() - 1970); // extract year from date
      setNewPatient((prevPatient) => ({
        ...prevPatient,
        age,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Pass the new patient data to the parent component
    const response = await axios.post(`${backendUrl}/patients`, newPatient);
    const response2 = await axios.post(`${backendUrl}/teeth`, {
      patient_id: newPatient.id,
    });
    console.log(response);
    setNewPatient({
      id: '',
      firstname: '',
      lastname: '',
      birthdate: '',
      email: '',
      phone: '',
      age: '',
      gender: '',
      isActive: true,
    });
    navigate('/');
  };
  const dragOverHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add('border-blue-500'); // Change border color to indicate drag over
  };
  const dragEnterHandler = (e) => {
    e.currentTarget.classList.add('border-blue-500'); // Change border color to indicate drag over
  };
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('border-blue-500'); // Remove border color when leaving the drop zone
  };
  const dropHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.remove('border-blue-500'); // Remove border color when dropping the file
    const files = e.dataTransfer.files;
    setImage(e.target.files[0]);
    setImageName(files[0].name);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(files[0]);
    // Handle the dropped files here
  };

  const handleImage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', image);
    formData.append('patient_id', newPatient.id);

    try {
      const response = await axios.post(`${backendUrl}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Image uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-2xl m-10 p-6 dark:text-black">
      {!agreed && (
        <div className="border border-gray-300 p-4 rounded-lg mb-4">
          <LegalAgreement onAgree={handleAgreement} />
        </div>
      )}

      {agreed && (
        <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-1">
          <div className="basis-1/2">
            <div>
              <div>
                <input
                  dir="rtl"
                  aria-describedby="helper-text-explanation"
                  id="firstname"
                  placeholder="מספר זהות"
                  variant="outlined"
                  type="text"
                  name="id"
                  value={newPatient.id}
                  onChange={handleChange}
                  required
                  className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark: dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div>
                <input
                  dir="rtl"
                  aria-describedby="helper-text-explanation"
                  id="firstname"
                  placeholder="שם פרטי"
                  variant="outlined"
                  type="text"
                  name="firstname"
                  value={newPatient.firstname}
                  onChange={handleChange}
                  required
                  className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark: dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div>
              <input
                id="outlined-basic"
                placeholder="שם משפחה"
                variant="outlined"
                type="text"
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark: dark:focus:ring-blue-500 dark:focus:border-blue-500"
                name="lastname"
                value={newPatient.lastname}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                id="outlined-basic"
                variant="outlined"
                dir="rtl"
                type="date"
                name="birthdate"
                value={newPatient.birthdate}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark: dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div>
              <input
                id="outlined-basic"
                placeholder="כתובת מייל"
                variant="outlined"
                type="email"
                name="email"
                value={newPatient.email}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark: dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div>
              <input
                id="outlined-basic"
                placeholder="מספר טלפון"
                variant="outlined"
                type="tel"
                dir="rtl"
                name="phone"
                value={newPatient.phone}
                onChange={handleChange}
                required
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark: dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div>
              <input
                id="outlined-basic"
                placeholder="גיל"
                variant="outlined"
                name="age"
                value={newPatient.age}
                onChange={handleChange}
                readOnly
                className="bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark: dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              <h2>מין </h2>
              <div
                className="flex items-center mb-4"
                name="gender"
                onChange={handleChange}
              >
                <input
                  id="gender"
                  type="radio"
                  value="זכר"
                  name="gender"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="male"
                  className="ms-2 text-sm font-medium text-white dark:text-white"
                >
                  זכר
                </label>

                <input
                  id="gender"
                  type="radio"
                  value="נקבה"
                  name="gender"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="female"
                  className="ms-2 text-sm font-medium text-white dark:text-white"
                >
                  נקבה
                </label>
              </div>
            </div>
            <div>
              <button
                onClick={changeHist}
                className=" bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                היסטוריה רפואית{' '}
              </button>

              {!mediacaHist ? (
                <div></div>
              ) : (
                <PatientMedicalHostory patient={newPatient} />
              )}
              <br />
              {!mediacaHist ? (
                <div></div>
              ) : (
                <button
                  type="submit"
                  className="text-xl   bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-green-600 dark:hover:bg-blue-700 dark:focus:ring-green-800"
                  size="medium"
                  variant="contained"
                >
                  הוספת מטופל
                </button>
              )}
            </div>
          </div>

          <div className="basis-1/3 m-14 ">
            <div>
              <div className="flex flex-grow h-auto items-center justify-center w-full">
                <label
                  onDragOver={dragOverHandler}
                  onDragEnter={dragEnterHandler}
                  onDragLeave={dragLeaveHandler}
                  onDrop={dropHandler}
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center
                justify-center h-auto w-full h-64 border-2 border-gray-300
                border-dashed rounded-lg cursor-pointer bg-gray-50
                dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100
                dark:border-gray-600 dark:hover:border-gray-500
                dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-black dark:"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Image Preview"
                        className="max-w-sm max-h-96 object-cover rounded-lg"
                      />
                    ) : (
                      <p className="mb-2 text-xl  ">
                        <span className="font-semibold">
                          {imageName ? imageName : 'Click to upload'}
                        </span>{' '}
                        or drag and drop
                      </p>
                    )}
                    <p className="text-xs  ">SVG, PNG, JPG or GIF</p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    name="pic"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
            </div>

            <div>
              <button
                className=" bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={handleImage}
              >
                העלאה
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default AddPatientFormDemo;
