import React, { useState, useEffect } from 'react';
import './login.css';
import backendUrl from '../../utils/config/config';
import axios from 'axios';

function Disease(props) {
  const [diseases, setDiseases] = useState({
    'שחזור מחומר מורכב': 450,
    'עקירת עצב': 300,
    'טיפול שורש - שן קדמית': 600,
    'טיפול שורש - שן מלתעה': 800,
    'טיפול שורש - שן טוחנת': 950,
    'טיפול שורש - שן טוחנת - 4 תעלות': 1100,
    'חידוש טיפול שורש': 1400,
    'מבנה מחומר מורכב': 350,
    עקירה: 350,
    'עקירה כירורגית': 650,
    'עקירת שן בינה': 800,
    'עקירת שן בינה - קלואה': 1600,
    'שתל דנטלי': 1600,
    'מבנה לשתל ישר': 400,
    'מבנה לשתל זוויתי': 400,
    'כתר זמני במרפאה': 300,
    'כתר זמני מבושל במעבדה': 400,
    'כתר חרסינה על בסיס מתכת': 1450,
    'כתר זרקוניה אסטיתי': 2200,
    'ציפוי זרקוניה': 3000,
    'עיצוב חניכיים': 400,
    'השתלת עצם': 1000,
  });
  const products_kid = {
    'שחזור מחומר מורכב': 300,
    'קיטוע מוח': 500,
    'טיפול שורש -שן חלב': 550,
    'כתר טרומי': 400,
    'שומר מקום': 400,
    'איטום חריצים': 200,
    עקירה: 300,
    'עקירה כירורגית': 650,
  };
  const [notes, setNotes] = useState('');
  const [choose, setChoose] = useState(
    props.choose ? props.choose.split(',') : ''
  );
  const teethId = props.teethId;
  let s = true;
  const patient = props.id;
  async function handleLogin(e) {
    e.preventDefault();
    console.log(choose);
    const response = await axios.put(`${backendUrl}/tooths/${patient.id}`, {
      toothNumber: teethId,
      status: choose.join(','),
    });
    console.log(response);
    
    
    choose.forEach(async (item) => {
      console.log(item,'item');
      // Check if the item exists as a key in products object
      if (diseases.hasOwnProperty(item)) {
        console.log('Item exists in products:', item);
        try {
          // Send Axios request for the item
          const response1 = await axios.post(`${backendUrl}/invoices`, {
            patient_id: patient.id,
            teeth_number: teethId,
            description: item,
            price: diseases[item],
            paid: false,
          });

          console.log('Response for product', item, ':', response1.data);
        } catch (error) {
          console.error('Error for product', item, ':', error);
        }
      } else 
      if (products_kid.hasOwnProperty(item)) {
        console.log('Item exists in products:', item);
        try {
          // Send Axios request for the item
          const response1 = await axios.post(`${backendUrl}/invoices`, {
            patient_id: patient.id,
            teeth_number: teethId,
            description: item,
            price: products_kid[item],
            paid: false,
          });

          console.log('Response for product', item, ':', response1.data);
        } catch (error) {
          console.error('Error for product', item, ':', error);
        }
      } else {
        console.warn('Product', item, 'not found in products object.');
      }
      
    }
    );
    
    props.toggle(e);
  }
  async function handleClick(e) {
    s = true;
    e.preventDefault();
    // Code to handle login goes here
    if (!choose.includes(e.target.id))
      await setChoose((choose) => [...choose, e.target.id]);
    else {
      setChoose((l) => l.filter((item) => item !== e.target.id));
    }
  }
  const handleRemoveItem = (e) => {
    const name = e.target.getAttribute('name');
    setChoose((l) => l.filter((item) => item !== name));
  };
  const handleRemoveD = (data) => {
    setChoose((l) => l.filter((item) => item !== data));
  };
  const defaultColor = 'bg-blue-300'; // Default color
  const activeColor = 'bg-blue-500'; // Active color
  const buttons = document.querySelectorAll('.btn'); // Select all buttons

  buttons.forEach((btn) => {
    btn.addEventListener('click', () => {
      // Rest all button colors
      buttons.forEach((b) => {
        b.classList.add(defaultColor);
        b.classList.remove(activeColor);
      });
      // Add active color on the clicked button, remove default color
      btn.classList.remove(defaultColor);
      btn.classList.add(activeColor);
    });
  });
  const handleSelectChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setDiseases(selectedOptions);
  };
  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newNotes = e.target.value.trim();
      if (newNotes && !choose.includes(newNotes)) {
        setChoose((prevChoose) => [...prevChoose, newNotes]);
      }
      setNotes('');
    }
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <button onClick={props.toggle}>סגור[x]</button>
        <form onSubmit={handleLogin}>
          <h1
            dir="rtl"
            className="flex justify-center items-center text-4xl font-extrabold"
          >
            <span
              dir="rtl"
              className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400"
            >
              שן -
            </span>
            {teethId}
          </h1>
          <div className="flex justify-center items-center" role="group">
            <div className="flex flex-wrap justify-center">
              {teethId <= 48
                ? Object.entries(diseases).map(([disease, price]) => (
                    <button
                      key={disease}
                      onClick={handleClick}
                      id={disease}
                      type="button"
                      className="btn text-white bg-blue-300 hover:bg-blue-800 text-sm font-medium m-2 px-4 py-2 rounded"
                    >
                      {disease}
                    </button>
                  ))
                : Object.entries(products_kid).map(([disease, price]) => (
                    <button
                      key={disease}
                      onClick={handleClick}
                      id={disease}
                      type="button"
                      className="btn text-white bg-blue-300 hover:bg-blue-800 text-sm font-medium m-2 px-4 py-2 rounded"
                    >
                      {disease}
                    </button>
                  ))}
            </div>
          </div>
          <br />
          <div className="flex justify-center items-center center ">
            {choose ? (
              choose.map((data) => (
                <div key={data} onClick={() => handleRemoveD(data)}>
                  <h2 className="m-2 text-xl font-extrabold leading-none tracking-tight">
                    {' '}
                    {data}{' '}
                  </h2>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          <div>
            <label>
              הערות:
              <input
                className="block mb-2 text-black text-2xl font-medium text-white dark:text-white"
                type="text"
                value={notes}
                onChange={handleNotesChange}
                onKeyPress={handleKeyPress}
              />
            </label>
          </div>
          <div className="flex justify-center items-center ">
            <button className="text-sm font-medium" type="submit">
              בוצע
            </button>
          </div>
        </form>
        <button onClick={props.toggle}>סגור[x]</button>
      </div>
    </div>
  );
}
export default Disease;
