import React from 'react';

const DoctorDrugDescription = ({
  doctorName,
  drugName,
  description,
  dosage,
  firstname,
  lastname,
  id,
  im,
}) => {
  const currentDate = new Date();

  return (
    <div
      style={{ direction: 'rtl' }}
      className=" rounded-lg bg-white shadow-lg px-8 py-10 mx-auto"
    >
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <div className=" font-bold text-lg"> מרפאת שיניים שרף בע״מ</div>
        </div>
        <div className="">
          <div className="text-xl mb-2 ">
            תאריך הפקת מרשם <br></br>
            <span className="underline font-bold text-xl mb-2">
              {currentDate.getDate()}/{currentDate.getMonth() + 1}/
              {currentDate.getFullYear()}
            </span>
          </div>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 pb-8 mb-8">
        <div className="font-semibold">{doctorName}</div>
        <h2 className="text-4xl font-bold m-0">עבור :</h2>
        <div className=" mb-2 ">
          {firstname} {lastname}
        </div>
        <div className="underline">{id}</div>
      </div>
      <table className="w-full text-left mb-8">
        <thead>
          <tr>
            <th className=" font-bold uppercase py-2">תרופה</th>
            <th className=" font-bold uppercase py-2">מינון</th>
            <th className=" font-bold uppercase py-2"> </th>
            <th className=" font-bold uppercase py-2">הערת רופא</th>
          </tr>
        </thead>
        <tbody>
          {drugName.map((drug, index) => (
            <tr key={index}>
              <td className="py-4 ">{drug}</td>
              <td className="py-4 ">{dosage}</td>
              <td className="py-4 "></td>
              <td className="py-4 ">{description} </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end mb-8">
        <div className=" mr-2"> </div>
      </div>
      <div className="text-right mb-8">
        <div className=" mr-2"> </div>
      </div>
      <div className="flex justify-end mb-8">
        <div className=" mr-2"> </div>
        <div className=" font-bold text-xl"></div>
      </div>
      <div className="border-t-2 border-gray-300 pt-8 mb-8">
        <div className=" mb-2 flex items-start">
          <img className="mr-auto w-1/4 h-1/3 object-contain" src={im} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DoctorDrugDescription;
