import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PatientCard from './PatientCard';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import backendUrl from '../../utils/config/config';

const PatientTable = () => {
  const [patients, setPatients] = useState([]);
  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const data = await axios.get(`${backendUrl}/patients`); // Adjust the URL as per your server
        setPatients(data.data);
        console.log(data.data);
      } catch (error) {}
    };

    fetchPatients();
  }, []);
  const location = useLocation();
  const { searchText } = location.state || {};

  const [searchInput, setSearchInput] = useState(searchText || '');
  const [showTable, setShowTable] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({});

  const filteredPatients = patients.filter((patient) => {
    const searchValue = searchInput.toLowerCase();
    return (
      (patient.id && patient.id.toString().includes(searchValue)) ||
      (patient.firstname &&
        patient.firstname.toLowerCase().includes(searchValue)) ||
      (patient.lastname &&
        patient.lastname.toLowerCase().includes(searchValue)) ||
      (patient.phone && patient.phone.includes(searchInput))
    );
  });

  // Pagination Logic

  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = filteredPatients.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const navigate = useNavigate();

  const goToToothChart = (patient) => {
    navigate(`/patient/tooth`, { state: { patient } });
  };
  const goToAccontment = (patient) => {
    navigate(`/patient/accountment`, { state: { patient } });
  };
  const [loading, setLoading] = useState(false);

  const handleDeletePatient = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`${backendUrl}/patients/${id}`);
      setPatients(patients.filter((patient) => patient.id !== id));
      setLoading(false);
    } catch (error) {
      console.error('Error deleting patient:', error);
      setLoading(false);
    }
  };
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  function cardVis(patient) {
    setShowTable(!showTable);
    setShowCard(!showCard);
    setCurrentPatient(patient);
  }

  return (
    <div className="px-12">
      <div>
        <h1 className="mb-4 m-2  p-4 text-center text-3xl font-extrabold text-white dark:text-white md:text-5xl lg:text-6xl text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
          מטופלים
        </h1>
      </div>
      <div className="table-wrapper">
        {/* Patient table */}
        {showTable && (
          <div>
            <div className="w-72">
              <input
                id="filled-basic"
                label="שם,טלפון,תז,שם משפחה"
                variant="filled"
                type="text"
                placeholder="שם,טלפון,תז,שם משפחה"
                className="bg-gray-50 border border-gray-50000 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={searchInput}
                onChange={handleSearchChange}
              />
            </div>
            <table className="custom-table">
              <thead>
                <tr>
                  <th className="custom-header">מספר זיהוי</th>
                  <th className="custom-header">שם פרטי</th>
                  <th className="custom-header">שם משפחה</th>
                  <th className="custom-header">תאריך לידה</th>
                  <th className="custom-header">אימייל</th>
                  <th className="custom-header">טלפון</th>
                  <th className="custom-header">גיל</th>
                  <th className="custom-header">מין</th>
                  <th className="custom-header">עדכון</th>
                  <th className="custom-header">מחיקה</th>
                  <th className="custom-header">טיפול</th>
                  <th className="custom-header">חשבון</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((patient) => (
                  <tr className="custom-row" key={patient.id}>
                    <td className="custom-cell text-blue-500">
                      <a onClick={() => goToToothChart(patient)}>
                        {patient.id}
                      </a>
                    </td>
                    <td className="custom-cell">{patient.firstname}</td>
                    <td className="custom-cell">{patient.lastname}</td>
                    <td className="custom-cell">{patient.birthdate}</td>
                    <td className="custom-cell">{patient.email}</td>
                    <td className="custom-cell">{patient.phone}</td>
                    <td className="custom-cell">{patient.age}</td>
                    <td className="custom-cell">
                      {patient.gender === 'זכר' ? 'ז' : 'נ'}
                    </td>
                    <td className="custom-cell">
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => cardVis(patient)}
                      >
                        עדכון
                      </button>
                    </td>
                    <td className="custom-cell">
                      <button
                        className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() => handleDeletePatient(patient.id)}
                        disabled={loading}
                      >
                        מחיקה
                      </button>
                    </td>
                    <td>
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => goToToothChart(patient)}
                      >
                        טיפול
                      </button>
                    </td>
                    <td>
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => goToAccontment(patient)}
                      >
                        חשבון
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <nav
                dir="ltr"
                className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
                aria-label="Table navigation"
              >
                <span
                  dir="rtl"
                  className="text-sm font-normal text-white dark:text-white mb-4 md:mb-0 block w-full md:inline md:w-auto"
                >
                  <span className="font-semibold text-white dark:text-white">
                    {indexOfLastItem}-{indexOfFirstItem + 1}{' '}
                  </span>{' '}
                  מתוך{' '}
                  <span className="font-semibold text-white dark:text-white">
                    {filteredPatients.length}
                  </span>
                </span>
                <ul
                  dir="rtl"
                  className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8 items-right"
                >
                  {Array.from({ length: totalPages }, (_, i) => (
                    <li key={i}>
                      <button
                        key={i + 1}
                        onClick={() => paginate(i + 1)}
                        className="flex navv items-center justify-center px-3 h-8 ms-0 leading-tight text-white  border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-white dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-blue-500 dark:focus:text-white dark:focus:ring-blue-500 dark:focus:text-white focus:ring-gray-500"
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        )}

        {showCard && (
          <>
            <button
              className="text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 "
              onClick={() => cardVis({})}
            >
              {' '}
              חזרה
            </button>
            <PatientCard patient={currentPatient} />
          </>
        )}
      </div>
    </div>
  );
};

export default PatientTable;
