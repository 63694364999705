import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PricingCard from './PricingCard';
import Invoice from './invoice';
import backendUrl from '../../utils/config/config';
import axios from 'axios';
import SaleCard from './SaleCard';


function Pricing() {
  const location = useLocation();
  const { patient } = location.state || {}; // Extract patient from location state

  const fullProducts= {
    'שחזור מחומר מורכב': 450,
    'עקירת עצב': 300,
    'טיפול שורש - שן קדמית': 600,
    'טיפול שורש - שן מלתעה': 800,
    'טיפול שורש - שן טוחנת': 950,
    'טיפול שורש - שן טוחנת - 4 תעלות': 1100,
    'חידוש טיפול שורש': 1400,
    'מבנה מחומר מורכב': 350,
    עקירה: 350,
    'עקירה כירורגית': 650,
    'עקירת שן בינה': 800,
    'עקירת שן בינה - קלואה': 1600,
    'שתל דנטלי': 1600,
    'מבנה לשתל ישר': 400,
    'מבנה לשתל זוויתי': 400,
    'כתר זמני במרפאה': 300,
    'כתר זמני מבושל במעבדה': 400,
    'כתר חרסינה על בסיס מתכת': 1450,
    'כתר זרקוניה אסטיתי': 2200,
    'ציפוי זרקוניה': 3000,
    'עיצוב חניכיים': 400,
    'השתלת עצם': 1000,
    'שחזור מחומר מורכב': 300,
    'קיטוע מוח': 500,
    'טיפול שורש -שן חלב': 550,
    'כתר טרומי': 400,
    'שומר מקום': 400,
    'איטום חריצים': 200,
    עקירה: 300,
    'עקירה כירורגית': 650,
    'ניקוי אבנית': 250,
    הקצעות: 700,
    הלבנה: 1500,
    'הרמת סינוס': 7000,
    'תותבת ': 7000,
    'תותבת מיידית': 2000,
    'ריפוד תותבת': 500,
    פליפר: 1200,
    'כפות פלוריד': 200,
    'ערה ראשונה': 200,
    'גז צחוק': 200,
  };
  const products = {
    'שחזור מחומר מורכב': 450,
    'עקירת עצב': 300,
    'טיפול שורש - שן קדמית': 600,
    'טיפול שורש - שן מלתעה': 800,
    'טיפול שורש - שן טוחנת': 950,
    'טיפול שורש - שן טוחנת - 4 תעלות': 1100,
    'חידוש טיפול שורש': 1400,
    'מבנה מחומר מורכב': 350,
    עקירה: 350,
    'עקירה כירורגית': 650,
    'עקירת שן בינה': 800,
    'עקירת שן בינה - קלואה': 1600,
    'שתל דנטלי': 1600,
    'מבנה לשתל ישר': 400,
    'מבנה לשתל זוויתי': 400,
    'כתר זמני במרפאה': 300,
    'כתר זמני מבושל במעבדה': 400,
    'כתר חרסינה על בסיס מתכת': 1450,
    'כתר זרקוניה אסטיתי': 2200,
    'ציפוי זרקוניה': 3000,
    'עיצוב חניכיים': 400,
    'השתלת עצם': 1000,
  };
  const products_kid = {
    'שחזור מחומר מורכב': 300,
    'קיטוע מוח': 500,
    'טיפול שורש -שן חלב': 550,
    'כתר טרומי': 400,
    'שומר מקום': 400,
    'איטום חריצים': 200,
    עקירה: 300,
    'עקירה כירורגית': 650,
  };
  const products_general = {
    'ניקוי אבנית': 250,
    הקצעות: 700,
    הלבנה: 1500,
    'הרמת סינוס': 7000,
    'תותבת ': 7000,
    'תותבת מיידית': 2000,
    'ריפוד תותבת': 500,
    פליפר: 1200,
    'כפות פלוריד': 200,
    'ערה ראשונה': 200,
    'גז צחוק': 200,
  };
  const [tooths, setTooths] = useState({
    patient_id: 1,
    t_11: null,
    t_12: null,
    t_13: null,
    t_14: null,
    t_15: null,
    t_16: null,
    t_17: null,
    t_18: null,
    t_21: null,
    t_22: null,
    t_23: null,
    t_24: null,
    t_25: null,
    t_26: null,
    t_27: null,
    t_28: null,
    t_31: null,
    t_32: null,
    t_33: null,
    t_34: null,
    t_35: null,
    t_36: null,
    t_37: null,
    t_38: null,
    t_41: null,
    t_42: null,
    t_43: null,
    t_44: null,
    t_45: null,
    t_46: null,
    t_47: null,
    t_48: null,
    t_51: null,
    t_52: null,
    t_53: null,
    t_54: null,
    t_55: null,
    t_61: null,
    t_62: null,
    t_63: null,
    t_64: null,
    t_65: null,
    t_71: null,
    t_72: null,
    t_73: null,
    t_74: null,
    t_75: null,
    t_81: null,
    t_82: null,
    t_83: null,
    t_84: null,
    t_85: null,
  });
const [discount, setDiscount] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await axios.get(`${backendUrl}/tooths/${patient.id}`); // Adjust the URL as per your server
        setTooths(data.data);

        Object.keys(data.data).forEach((key) => {
          if (data.data[key]) {
          }
        });
      } catch (err) {
      }
    };
    fetchData();
  }, []);
  return (
    <div className="bg-gray-700 px-4 py-16 min-h-screen flex">
      <div
        // aria-hidden="true"
        className="absolute  h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-20"
      ></div>
      <div className=" mx-auto px-6 md:px-12 xl:px-6">
        <div className="mb-10 space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold text-white sm:text-3xl md:text-4xl">
            חשבון עבור-
            {` ${patient.firstname} ${patient.lastname} `}
            <h2 className="text-center text-2xl font-bold text-white ">
              תז: {patient.id}
            </h2>
          </h2>
        </div>

        <div className="grid grid-cols-3 m-2 justify-center gap-4">
          <SaleCard setDiscount={setDiscount}></SaleCard>

          {Object.keys(tooths).map((teeth, index) =>
            teeth.slice(-2) <= 55 ? (
              <PricingCard
                key={index}
                patient={patient}
                teeth={teeth}
                exam={tooths[teeth]}
                products={fullProducts}
              ></PricingCard>
            ) : (
              <PricingCard
                key={index}
                patient={patient}
                teeth={teeth}
                exam={tooths[teeth]}
                products={fullProducts}
              ></PricingCard>
            )
          )}

          {/* Extras Column */}
          {/* <div className="col-span-1 sm:col-span-2 md:col-span-3 items-center p-4 sm:p-8  rounded-3xl bg-gray-800 border-gray-700 shadow-gray-600/10 shadow-none m-2 max-w-md"> */}
          <PricingCard
            key={1}
            patient={patient}
            teeth={4}
            exam="כללי"
            products={products_general}
          ></PricingCard>
          {/* </div> */}
        </div>
      </div>
      <Invoice discount={discount} patient={patient}></Invoice>
    </div>
  );
}

export default Pricing;
