import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import backendUrl from '../../utils/config/config';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/he'; // Import Hebrew locale for moment
import './MyCalendar.css'; // Import your custom CSS

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({ title: '', start: '', end: '' });
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  const navigate = useNavigate();

  const parseEvents = (response) => {
    return response.map((event) => ({
      title: event.name || 'No Title',
      start: new Date(event.startDatetime),
      end: new Date(event.endDatetime || event.startDatetime), // Use startDatetime if endDatetime is empty
      id: event.id,
      allDay: false,
    }));
  };
  const [events, setEvents] = useState();

  useEffect(() => {
    // Fetch patient data from the database using Axios
    axios
      .get(`${backendUrl}/patientsname`)
      .then((response) => {
        setPatients(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error('Error fetching patients:', error);
      });
    axios
      .get(`${backendUrl}/appointments`)
      .then((res) => {
        let dates = parseEvents(res.data);
        setEvents(dates);
        console.log(dates);
      })
      .catch((error) => {
        console.error('Error fetching patients:', error);
      });
  }, []);
  useEffect(() => {
    // Fetch patient data from the database using Axios
    axios
      .get(`${backendUrl}/appointments`)
      .then((res) => {
        let dates = parseEvents(res.data);
        setEvents(dates);
        console.log(dates);
      })
      .catch((error) => {
        console.error('Error fetching patients:', error);
      });
  }, [selectedEvent]);

  const handleSelectSlot = async ({ start, end }) => {
    setNewEvent({ ...newEvent, start, end });
  };
  const reverseParseEvents = (event) => {
    return {
      startDatetime: event.start,
      endDatetime: event.end,
      name: event.title,
      id: event.id,
    };
  };

  const handleAddEvent = async () => {
    setEvents([
      ...events,
      {
        ...newEvent,
        start: new Date(newEvent.start),
        end: new Date(newEvent.end),
      },
    ]);
    let beEvent = reverseParseEvents(newEvent);
    console.log(beEvent);
    try {
      const response = await axios.post(`${backendUrl}/appointments`, beEvent, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('appointment uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading appointment:', error);
    }
    setNewEvent({ title: '', start: '', end: '' });
    setEvents([]); // Clear the events before re-fetching to ensure state update
    const response = await axios.get(`${backendUrl}/appointments`);
    const fetchedEvents = parseEvents(response.data);
    setEvents(fetchedEvents);
  };

  const handleDeleteEvent = async () => {
    if (selectedEvent) {
      setEvents(events.filter((event) => event !== selectedEvent));
      let id = selectedEvent.id;
      setSelectedEvent(null);
      alert(id);
      try {
        const response = await axios.delete(`${backendUrl}/appointments/${id}`);
        console.log('appointment deleted successfully:', response.data);
      } catch (error) {
        console.error('Error delete appointment:', error);
      }
      setEvents([]); // Clear the events before re-fetching to ensure state update
      const response = await axios.get(`${backendUrl}/appointments`);
      const fetchedEvents = parseEvents(response.data);
      setEvents(fetchedEvents);
    }
  };

  const formatEventDate = (date) => {
    moment.locale('he');
    return moment(date).format('dddd, MMMM Do, HH:mm');
  };

  return (
    <div className="p-4 w-150 mr-16">
      <div className="mb-4">
        <h2 className="text-2xl font-bold">ניהול תורים</h2>
        <div className="flex flex-wrap gap-4 mt-2 m-4">
          <input
            type="text"
            placeholder="שם מטופל"
            value={newEvent.title}
            onChange={(e) =>
              setNewEvent({ ...newEvent, title: e.target.value })
            }
            className="w-1/4 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            list="patientsList"
          />
          <datalist id="patientsList">
            {patients.map((patient) => (
              <option
                key={`${patient.firstname}${patient.id}`}
                value={`${patient.firstname} ${patient.lastname} -- ${patient.id}`}
              />
            ))}
          </datalist>
          <div>
            <label className="mt-2 m-4">התחלה </label>
            <DatePicker
              selected={newEvent.start ? new Date(newEvent.start) : null}
              onChange={(date) => setNewEvent({ ...newEvent, start: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dddd, MMMM Do, HH:mm"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="mt-2  m-4">סיום</label>
            <DatePicker
              selected={newEvent.end ? new Date(newEvent.end) : null}
              onChange={(date) => setNewEvent({ ...newEvent, end: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dddd, MMMM Do, HH:mm"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            onClick={handleAddEvent}
            className="mt-6 w-full py-2 bg-green-500 text-white rounded-lg hover:bg-green-700"
          >
            הוספת תור
          </button>
        </div>
      </div>
      {selectedEvent && (
        <div className="m-4">
          <h3 className="text-xl font-bold">תור שנבחר</h3>
          <p>{`כותרת: ${selectedEvent.title}`}</p>
          <p>{`התחלה: ${formatEventDate(selectedEvent.start)}`}</p>
          <p>{`סיום: ${formatEventDate(selectedEvent.end)}`}</p>
          <p>{`מטופל: ${selectedEvent.title.split(' -- ')[1]}`} </p>
          {selectedEvent.title.split(' -- ')[1] && (
            <button
              onClick={() => {
                navigate(`/patient/tooth`, { state: { patient } });
              }}
              className="m-2 w-24 mr-0 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
            >
              דף לקוח
            </button>
          )}
          <button
            onClick={handleDeleteEvent}
            className="m-2 w-24 py-2 bg-red-500 text-white rounded-lg hover:bg-red-700"
          >
            מחק תור
          </button>
        </div>
      )}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable
        onSelectEvent={async (event) => {
          setSelectedEvent(event);
          try {
            let id = event.title.split(' -- ')[1].trim();
            const data = await axios.get(`${backendUrl}/patients/${id}`); // Adjust the URL as per your server
            setPatient(data.data);
            console.log('data--> ', data.data);
          } catch (error) {
            console.error(error);
          }
        }}
        onSelectSlot={handleSelectSlot}
        popup
      />
    </div>
  );
};

export default MyCalendar;
