import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear token from localStorage (assuming you stored it as 'token')
    localStorage.removeItem('token');
    // Redirect to login page or any other appropriate route
    navigate('/login');
  };

  return (
    <button
      onClick={handleLogout}
      type="button"
      style={{
        backgroundColor: '#e53e3e', // Red background color
        color: 'white', // Text color
        fontSize: '1rem', // Font size
        padding: '0.75rem 1rem', // Padding
        borderRadius: '0.375rem', // Rounded corners
        fontWeight: 'medium', // Font weight
        display: 'flex', // Flex display
        alignItems: 'center', // Center items
        justifyContent: 'center', // Center justify
        outline: 'none', // Remove outline
        border: 'none', // No border
        cursor: 'pointer', // Pointer cursor
        width: '100%', // Full width
        textAlign: 'center', // Center text
        transition: 'background-color 0.2s ease-in-out', // Transition
      }}
    >
      <i className="fa fa-sign-out"></i>
      <span className="mr-3 hidden group-hover:block">התנתקות</span>
    </button>
  );
};

export default LogoutButton;
