import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './navbar';

export default function Layout() {
  return (
    <div className="m-2">
      <NavBar />
      <main className="mx-2 sm:mx-4 md:w-full transition-all duration-300">
        {/* Your main content goes here */}
        <Outlet />
      </main>
    </div>
  );
}
