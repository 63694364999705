import React, { useState, useEffect } from 'react';
import './PricingCard.css';
import backendUrl from '../../utils/config/config';
import axios from 'axios';

const PricingCard = ({ patient, teeth, exam, index, products }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    shoresh: false,
    other: false,
    keter: false,
    akera: false,
    all: false,
  });
  const filterProducts = (filterString) => {
    if (filterString !== 'עקירה' || filterString !== 'עקירת')
      return Object.fromEntries(
        Object.entries(products).filter(
          ([key, value]) =>
            key.includes(filterString) ||
            value.toString().includes(filterString)
        )
      );
    else
      return Object.fromEntries(
        Object.entries(products).filter(
          ([key, value]) =>
            key.includes(filterString) ||
            key.includes('עקירת' || filterString !== 'עקירת')
        )
      );
  };

  const shoreshProducts = filterProducts('שורש');
  const keterProducts = filterProducts('כתר');
  const akeraProducts = filterProducts('עקירה');
  const otherProducts = Object.fromEntries(
    Object.entries(products).filter(
      ([key, value]) =>
        !key.includes('עקירה') && !key.includes('כתר') && !key.includes('שורש')
    )
  );

  const handleProductChange = (event) => {
    const selectedProduct = event.target.value;
    const productPrice = products[selectedProduct];
    const newSelectedProducts = [...selectedProducts, selectedProduct];
    setSelectedProducts(newSelectedProducts);
    setTotal(total + productPrice);
    async function fetchData() {
      const response = await axios.post(`${backendUrl}/invoices`, {
        patient_id: patient.id,
        teeth_number: teeth,
        description: selectedProduct,
        price: productPrice,
        paid: false,
      });
    }
    fetchData();
  };

  const handleRemoveProduct = async (product) => {
    alert(product)
    const newSelectedProducts = selectedProducts.filter(
      (item) => item !== product
    );
    setSelectedProducts(newSelectedProducts);

    const response = await axios.put(`${backendUrl}/tooths/${patient.id}`, {
      toothNumber: teeth.slice(-2),
      status: newSelectedProducts.join(','),
    });
    console.log(response.status);
  };

  const toggleDropdown = (block) => {
    setIsDropdownOpen((prevState) => ({
      ...prevState,
      [block]: !prevState[block],
    }));
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${backendUrl}/tooths/${patient.id}`);
      setSelectedProducts(String(response.data[teeth]).split(`,`));
      setTotal(
        String(response.data[teeth])
          .split(`,`)
          .reduce((sum, prod) => {
            return sum + (products[prod] || 0);
          }, 0)
      );
    }
    fetchData();
  }, []);

  return (
    exam &&
    !String(exam).includes('Done') &&
    teeth !== 'patient_id' && (
      <div
        key={index}
        className="flex flex-col items-center aspect-auto p-4 sm:p-8 border rounded-3xl bg-gray-800 border-gray-700 shadow-gray-600/10 shadow-none m-2 flex-1 max-w-md "
      >
        {teeth === 4 ? (
          <p className="text-3xl font-medium text-white mb-2">כללי</p>
        ) : (
          <p className="text-3xl font-medium text-white mb-2">{`שן ${teeth.slice(
            2
          )}`}</p>
        )}

        <ul className="list-none text-3xl font-bold list-inside mb-6 text-center text-white">
          {selectedProducts.map(
            (prod, index) =>
              prod != '' &&
              prod !== undefined &&
              prod !== 'undefined' && (
                <li key={index} className="mb-2 text-xl">
                  {prod} - ₪{products[prod]} <br />
                  <button onClick={() => handleRemoveProduct(prod)}>
                    <span className="text-red-500">X מחק</span>
                  </button>
                </li>
              )
          )}
        </ul>
        <p className="text-3xl font-bold mb-2 text-center text-white">
          ₪{total}
        </p>
        <br />
        {/* Dropdown button */}
        <button
          id="multiLevelDropdownButton"
          data-dropdown-toggle="multi-dropdown"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
          onClick={() => {
            toggleDropdown('all');
          }}
        >
          בחירת סוג טיפול
          <svg
            className={`w-2.5 h-2.5 ms-3 ${
              isDropdownOpen['all'] ? 'rtl:rotate-180' : ''
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        {/* Dropdown menu */}
        <div
          id="multi-dropdown"
          className={`z-10 ${
            isDropdownOpen['all'] ? '' : 'hidden'
          }  divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
        >
          <ul
            className="py-2 text-sm text-white dark:text-gray-200"
            aria-labelledby="multiLevelDropdownButton"
          >
            <li>{/* Double Dropdown */}</li>
            <li>
              <button
                id="doubleDropdownButton"
                data-dropdown-toggle="doubleDropdown"
                data-dropdown-placement="right-start"
                type="button"
                className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => toggleDropdown('shoresh')}
              >
                טיפולי שורש
                <svg
                  className={`w-2.5 h-2.5 ms-3 ${
                    isDropdownOpen['shoresh'] ? 'rtl:rotate-180' : ''
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
            <li>
              <div
                id="doubleDropdown"
                className={`z-10 ${
                  isDropdownOpen['shoresh'] ? '' : 'hidden'
                }  divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
              >
                <ul
                  className="py-2 text-sm text-white dark:text-gray-200"
                  aria-labelledby="doubleDropdownButton"
                >
                  {Object.keys(shoreshProducts).map((product, index) => (
                    <li key={index}>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          handleProductChange({ target: { value: product } });
                          setIsDropdownOpen(false);
                        }}
                      >
                        {product} - ₪{products[product]}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li>
              <button
                id="doubleDropdownButton"
                data-dropdown-toggle="doubleDropdown"
                data-dropdown-placement="right-start"
                type="button"
                className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => toggleDropdown('akera')}
              >
                עקירות
                <svg
                  className={`w-2.5 h-2.5 ms-3 ${
                    isDropdownOpen['akera'] ? 'rtl:rotate-180' : ''
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
            <li>
              <div
                id="doubleDropdown"
                className={`z-10 ${
                  isDropdownOpen['akera'] ? '' : 'hidden'
                }  divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
              >
                <ul
                  className="py-2 text-sm text-white dark:text-gray-200"
                  aria-labelledby="doubleDropdownButton"
                >
                  {Object.keys(akeraProducts).map((product, index) => (
                    <li key={index}>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          handleProductChange({ target: { value: product } });
                          setIsDropdownOpen(false);
                        }}
                      >
                        {product} - ₪{products[product]}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </li>

            <li>
              <button
                id="doubleDropdownButton"
                data-dropdown-toggle="doubleDropdown"
                data-dropdown-placement="right-start"
                type="button"
                className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => toggleDropdown('keter')}
              >
                כתרים
                <svg
                  className={`w-2.5 h-2.5 ms-3 ${
                    isDropdownOpen['keter'] ? 'rtl:rotate-180' : ''
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
            <li>
              <div
                id="doubleDropdown"
                className={`z-10 ${
                  isDropdownOpen['keter'] ? '' : 'hidden'
                }  divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
              >
                <ul
                  className="py-2 text-sm text-white dark:text-gray-200"
                  aria-labelledby="doubleDropdownButton"
                >
                  {Object.keys(keterProducts).map((product, index) => (
                    <li key={index}>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          handleProductChange({ target: { value: product } });
                          setIsDropdownOpen(false);
                        }}
                      >
                        {product} - ₪{products[product]}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </li>

            <li>
              <button
                id="doubleDropdownButton"
                data-dropdown-toggle="doubleDropdown"
                data-dropdown-placement="right-start"
                type="button"
                className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => toggleDropdown('other')}
              >
                אחר ..
                <svg
                  className={`w-2.5 h-2.5 ms-3 ${
                    isDropdownOpen['other'] ? 'rtl:rotate-180' : ''
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
            <li>
              <div
                id="doubleDropdown"
                className={`z-10 ${
                  isDropdownOpen['other'] ? '' : 'hidden'
                }  divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
              >
                <ul
                  className="py-2 text-sm text-white dark:text-gray-200"
                  aria-labelledby="doubleDropdownButton"
                >
                  {Object.keys(otherProducts).map((product, index) => (
                    <li key={index}>
                      <button
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => {
                          handleProductChange({ target: { value: product } });
                          setIsDropdownOpen(false);
                        }}
                      >
                        {product} - ₪{products[product]}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  );
};

export default PricingCard;
