// InvoiceToPrint.jsx
import { disconnect } from 'process';
import React from 'react';

const InvoiceToPrint = React.forwardRef(
  (
    {
      patient,
      currentDate,
      products,
      selectedProducts,
      total,
      discount,
      selectedProductsInvoice,
    },
    ref
  ) => (
    <div
      ref={ref}
      className="bg-white rounded-lg shadow-lg px-8 py-10 max-w-xl mx-auto"
      style={{ direction: 'rtl' }}
    >
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <div className=" font-semibold text-lg">שרף קליניק</div>
        </div>
        <div className="">
          <div className="font-bold text-xl mb-2">הצעת מחיר</div>
          <div className="text-sm">
            תאריך :{currentDate.getDate()}/{currentDate.getMonth() + 1}/
            {currentDate.getFullYear()}
          </div>
          <div className="text-sm">
            הצעה מספר:{`${(patient.id % 1000) - currentDate.getFullYear()}`}
          </div>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 pb-8 mb-8">
        <h2 className="text-2xl font-bold mb-4">עבור :</h2>
        <div className=" mb-2">
          {patient.firstname} {patient.lastname}
        </div>
        <div className=" mb-2">{patient.email}</div>
        <div className="">{patient.phone}</div>
      </div>
      <table className="w-full text-left mb-8">
        <thead>
          <tr>
            <th className=" font-bold uppercase py-2">שן</th>
            <th className=" font-bold uppercase py-2">פירוט</th>
            <th className=" font-bold uppercase py-2"></th>
            <th className=" font-bold uppercase py-2">סכום</th>
          </tr>
        </thead>
        <tbody>
          {(selectedProducts || []).map((productArray, index) => (
            <React.Fragment key={index}>
              {Object.entries(productArray).map(
                ([teethId, treatments], innerIndex) =>
                  treatments.map((treatment, treatmentIndex) => (
                    <tr
                      className="aa bg-gray-200 text-black"
                      key={`${index}-${innerIndex}-${treatmentIndex}`} // Ensure a unique key
                    >
                      <td className="py-4 ">{teethId.slice(-2)}</td>
                      <td className="py-4 ">{treatment}</td>
                      <td className="py-4 "></td>
                      <td className="py-4 ">{products[treatment]} ₪</td>
                    </tr>
                  ))
              )}
            </React.Fragment>
          ))}

          {(selectedProductsInvoice || []).map((product1, index) => (
            <tr className="aa bg-gray-200 text-black" key={`invoice-${index}`}>
              {' '}
              {/* Ensure a unique key */}
              <td className="aa py-4 text-black">
                {product1.teeth_number !== 4
                  ? String(product1.teeth_number).slice(-2)
                  : 'כללי'}
              </td>
              <td className="py-4 text-gray-700">{product1.description}</td>
              <td className="py-4 text-gray-700"></td>
              <td className="py-4 text-gray-700">{product1.price} ₪</td>
            </tr>
          ))}

          {discount > 0 && (
            <tr key="discount" className="bg-red-200 text-black bbb">
              <td className="py-4 ">הנחה</td>
              <td className="py-4 "></td>
              <td className="py-4 "></td>
              <td className="py-4 ">{discount} - ₪</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex justify-end mb-8">
        <div className=" mr-2"> סה״כ :</div>
        <div className="">{total} ₪</div>
      </div>
      <div className="text-right mb-8">
        <div className=" mr-2">מע״מ:</div>
        <div className="">{Math.ceil(total * 0.17)} ₪</div>
      </div>
      <div className="flex justify-end mb-8">
        <div className=" mr-2">סה״כ :</div>
        <div className=" font-bold text-xl">{Math.ceil(total * 1.17)} ₪</div>
      </div>
      <div className="border-t-2 border-gray-300 pt-8 mb-8">
        <div className=" mb-2">
          משמש לתיעוד עסקה שנעשתה בין שני הצדדים אך אינו נחשב למסמך לצורך ניהול
          פנקסי חשבונות, אינו מחייב תשלום מיסים עבורו ואינו מאפשר לצד המקבל
          להזדכות מול מס הכנסה בגינו , חשבון עסקה הוא מסמך המהווה חיוב ללקוח
        </div>
      </div>
    </div>
  )
);

export default InvoiceToPrint;
