import React, { useEffect, useState, useRef } from 'react';
import backendUrl from '../../utils/config/config';
import axios from 'axios';
import InvoiceToPrint from './invoicetoprint';
import { useReactToPrint } from 'react-to-print';

function Invoice({ patient, discount }) {
  const currentDate = new Date();
  const products = {
    'שחזור מחומר מורכב': 450,
    'עקירת עצב': 300,
    'טיפול שורש - שן קדמית': 600,
    'טיפול שורש - שן מלתעה': 800,
    'טיפול שורש - שן טוחנת': 950,
    'טיפול שורש - שן טוחנת - 4 תעלות': 1100,
    'חידוש טיפול שורש': 1400,
    'מבנה מחומר מורכב': 350,
    עקירה: 350,
    'עקירה כירורגית': 650,
    'עקירת שן בינה': 800,
    'עקירת שן בינה - קלואה': 1600,
    'שתל דנטלי': 1600,
    'מבנה לשתל ישר': 400,
    'מבנה לשתל זוויתי': 400,
    'כתר זמני במרפאה': 300,
    'כתר זמני מבושל במעבדה': 400,
    'כתר חרסינה על בסיס מתכת': 1450,
    'כתר זרקוניה אסטיתי': 2200,
    'ציפוי זרקוניה': 3000,
    'עיצוב חניכיים': 400,
    'השתלת עצם': 1000,
    'שחזור מחומר מורכב': 300,
    'קיטוע מוח': 500,
    'טיפול שורש -שן חלב': 550,
    'כתר טרומי': 400,
    'שומר מקום': 400,
    'איטום חריצים': 200,
    עקירה: 300,
    'עקירה כירורגית': 650,
    'ניקוי אבנית': 250,
    הקצעות: 700,
    הלבנה: 1500,
    'הרמת סינוס': 7000,
    'תותבת ': 7000,
    'תותבת מיידית': 2000,
    'ריפוד תותבת': 500,
    פליפר: 1200,
    'כפות פלוריד': 200,
    'ערה ראשונה': 200,
    'גז צחוק': 200,
  };
  const [tooths, setTooths] = useState({
    t_11: null,
    t_12: null,
    t_13: null,
    t_14: null,
    t_15: null,
    t_16: null,
    t_17: null,
    t_18: null,
    t_21: null,
    t_22: null,
    t_23: null,
    t_24: null,
    t_25: null,
    t_26: null,
    t_27: null,
    t_28: null,
    t_31: null,
    t_32: null,
    t_33: null,
    t_34: null,
    t_35: null,
    t_36: null,
    t_37: null,
    t_38: null,
    t_41: null,
    t_42: null,
    t_43: null,
    t_44: null,
    t_45: null,
    t_46: null,
    t_47: null,
    t_48: null,
    t_51: null,
    t_52: null,
    t_53: null,
    t_54: null,
    t_55: null,
    t_61: null,
    t_62: null,
    t_63: null,
    t_64: null,
    t_65: null,
    t_71: null,
    t_72: null,
    t_73: null,
    t_74: null,
    t_75: null,
    t_81: null,
    t_82: null,
    t_83: null,
    t_84: null,
    t_85: null,
  });
  let click = 0;
  const [total, setTotal] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const componentRef = useRef();
  const [selectedProductsInvoice, setSelectedProductsInvoice] = useState();
  const [totalInvoice, setTotalInvoice] = useState(0);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    let newTotal = 0;
    for (var teeth in tooths) {
      if (tooths[teeth]) {
        newTotal += products[tooths[teeth]] || 0; // Assuming products[teeth] gives the product value
      }
    }
   
    async function fetchData() {
      try {
        const response = await axios.get(`${backendUrl}/tooths/${patient.id}`);
        console.log(response.data);
        const values = Object.keys(response.data)
          .filter(
            (key) =>
              key !== 'patient_id' &&
              response.data[key] != null &&
              response.data[key] != ''
          )
          .map((key) => ({ [key]: response.data[key].split(',') }));
        console.log(response.data);
        setSelectedProducts(values);
        let totalPrice = 0;
        values.forEach((item) => {
          Object.values(item).forEach((pridctes) => {
            pridctes.forEach((pr) => {
              totalPrice += products[pr] || 0;
            });
          });
        });
        setTotal(totalPrice);
        // console.log(response.data.row);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    async function fetchDataInvoice() {
      try {
        const response = await axios.get(`${backendUrl}/invoices/${patient.id}`);
        const invoiceData = response.data.row;
        const pricesListInvoice = invoiceData.map((item) => item.price);
        setSelectedProductsInvoice(invoiceData);
        const totalPriceInvoice = pricesListInvoice.reduce(
          (acc, price) => acc + price,
          0
        );
        setTotalInvoice(totalPriceInvoice);
        // console.log(response.data.row);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  

    fetchData();
    // fetchDataInvoice();
  }, [click]);
  const openGmailCompose = () => {
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
      patient.email
    )}&su=${encodeURIComponent('הצעת מחיר')}`;
    window.open(gmailUrl, '_blank');
  };

  return (
    <div className="p-4">
      <button
        className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={handlePrint}
      >
        הדפסה
      </button>
      <button
        className="text-white mx-2 bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-green-700 dark:focus:ring-blue-800"
        onClick={openGmailCompose}
      >
        שליחה במייל
      </button>
      <button
        className="text-white mx-2 bg-blue-400 hover:bg-black-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-black-600 dark:hover:bg-black-700 dark:focus:ring-black-800"
        onClick={() => {
          click = 10;
        }}
      >
        רענון
      </button>

      <InvoiceToPrint
        ref={componentRef}
        patient={patient}
        products={products}
        currentDate={currentDate}
        selectedProducts={selectedProducts}
        discount={discount}
        selectedProductsInvoice={selectedProductsInvoice}
        total={total+totalInvoice- discount}
      />
    </div>
  );
}

export default Invoice;
