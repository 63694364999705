import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import serviceImage1 from '../../images/drug.jpg'; // Update with the correct path to service image 1
import serviceImage2 from '../../images/tepol.jpg'; // Update with the correct path to service image 2
import serviceImage3 from '../../images/yoman.jpg'; // Update with the correct path to service image 2
import serviceImage4 from '../../images/chair.jpg'; // Update with the correct path to service image 3
import logo from '../../images/logo.png';
import homepage_bg from '../../images/chair4.jpeg';
const HomePage = () => {
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/patients', { state: { searchText } });
  };

  return (
    <div
      className="flex flex-col text-white"
      style={{
        backgroundImage: `url(${homepage_bg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <a href="/" className="flex flex-col items-center mb-8">
        <img src={logo} className="w-96" alt="Logo" />
        <span className="self-center text-white text-6xl font-semibold whitespace-nowrap text-neutral hidden group-hover:block">
          מנטל
        </span>
      </a>
      {/* Services Section */}
      <section id="services" className="py-16 px-4">
        <div className="flex justify-center">
          <div className="w-1/2 grid grid-cols-2 gap-4 grid-flow-row-dense ">
            <div className=" shadow-lg rounded-lg overflow-hidden mb-4 group relative">
              <a href="drugs" className="block">
                <img
                  src={serviceImage1}
                  alt="Service 1"
                  className="w-full h-72 object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <h4 className="text-2xl font-bold">הפקת מרשמים</h4>
                  <p className="mt-2 text-white">
                    הפקת מרשמים למטופלים רשומים או לא רשומים
                  </p>
                </div>
              </a>
            </div>
            <div className="shadow-lg rounded-lg overflow-hidden mb-4 group relative">
              <a href="patients" className="block">
                <img
                  src={serviceImage2}
                  alt="Service 1"
                  className="w-full h-72 object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <h4 className="text-2xl font-bold text-white">התחלת טיפול</h4>
                  <p className="mt-2 text-white">
                    חיפוש מטופל/הרשמת מטולים ו התחלת טיפולים
                  </p>
                </div>
              </a>
            </div>
            <div className="shadow-lg rounded-lg overflow-hidden mb-4 group relative">
              <a href="appointments">
                <img
                  src={serviceImage3}
                  alt="Service 3"
                  className="w-full h-72 object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <h4 className="text-2xl font-bold">קביעת תורים</h4>
                  <p className="mt-2 text-white">ניהול תורי מטופלים</p>
                </div>
              </a>
            </div>
            <div className="shadow-lg rounded-lg overflow-hidden mb-4 group relative">
              <p href="appointments">
                <img
                  src={serviceImage4}
                  alt="Service 3"
                  className="w-full h-72 object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <h4 className="text-2xl font-bold"> חיפוש מטופל</h4>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="חפש.."
                      className="border p-2 rounded"
                    />
                  </form>
                </div>
              </p>
            </div>
          </div>
        </div>
        <footer className="bg-gray-700 w-full font-bold text-white text-center p-4 mt-auto">
          <p>
            &copy; {new Date().getFullYear()} Dental Clinic. All rights
            reserved.
          </p>
        </footer>
      </section>
    </div>
  );
};

export default HomePage;
