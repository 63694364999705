import React, { useState, useRef } from 'react';
import DoctorDrugDescription from './DoctorDrugDescription';
import { drs } from './dr';
import { useReactToPrint } from 'react-to-print';
import razen from '../../images/razen.png';
import sharaf from '../../images/sharaf.png';

const Drugs = () => {
  const [patient, setPatient] = useState({
    firstname: '',
    lastname: '',
    id: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDrugs, setSelectedDrugs] = useState([]);
  const [doctorName, setDoctorName] = useState('');
  const [description, setDescription] = useState('');
  const [dosage, setDosage] = useState('');
  const doctors = ['דר.נאטור שרף', 'דר.נאטור רזין'];
  const sign = {
    'דר.נאטור שרף': sharaf,
    'דר.נאטור רזין': razen,
  };
  const dosages = [
    '50 מ"ג, פעם ביום למשך 5 ימים',
    '50 מ"ג, פעמיים ביום למשך 5 ימים',
    '50 מ"ג, 3 פעמים ביום למשך 5 ימים',
    '100 מ"ג, פעם ביום למשך 7 ימים',
    '100 מ"ג, פעמיים ביום למשך 7 ימים',
    '100 מ"ג, 3 פעמים ביום למשך 7 ימים',
    '150 מ"ג, פעם ביום למשך 10 ימים',
    '150 מ"ג, פעמיים ביום למשך 10 ימים',
    '150 מ"ג, 3 פעמים ביום למשך 10 ימים',
    '200 מ"ג, פעם ביום למשך 10 ימים',
    '200 מ"ג, פעמיים ביום למשך 10 ימים',
    '200 מ"ג, 3 פעמים ביום למשך 10 ימים',
    '250 מ"ג, פעם ביום למשך שבועיים',
    '250 מ"ג, פעמיים ביום למשך שבועיים',
    '250 מ"ג, 3 פעמים ביום למשך שבועיים',
    '300 מ"ג, פעם ביום למשך 14 ימים',
    '300 מ"ג, פעמיים ביום למשך 14 ימים',
    '300 מ"ג, 3 פעמים ביום למשך 14 ימים',
    '350 מ"ג, פעם ביום למשך 10 ימים',
    '350 מ"ג, פעמיים ביום למשך 10 ימים',
    '350 מ"ג, 3 פעמים ביום למשך 10 ימים',
    '400 מ"ג, פעם ביום למשך 14 ימים',
    '400 מ"ג, פעמיים ביום למשך 14 ימים',
    '400 מ"ג, 3 פעמים ביום למשך 14 ימים',
    '500 מ"ג, פעם ביום למשך שבוע',
    '500 מ"ג, פעמיים ביום למשך שבוע',
    '500 מ"ג, 3 פעמים ביום למשך שבוע',
    '600 מ"ג, פעם ביום למשך שבוע',
    '600 מ"ג, פעמיים ביום למשך שבוע',
    '600 מ"ג, 3 פעמים ביום למשך שבוע',
    '700 מ"ג, פעם ביום למשך שבועיים',
    '700 מ"ג, פעמיים ביום למשך שבועיים',
    '700 מ"ג, 3 פעמים ביום למשך שבועיים',
    '800 מ"ג, פעם ביום למשך שבועיים',
    '800 מ"ג, פעמיים ביום למשך שבועיים',
    '800 מ"ג, 3 פעמים ביום למשך שבועיים',
    '875 מ"ג, פעם ביום למשך שבועיים',
    '875 מ"ג, פעמיים ביום למשך שבועיים',
    '875 מ"ג, 3 פעמים ביום למשך שבועיים',
  ];

  const handlePatientChange = (event) => {
    const { name, value } = event.target;
    setPatient((prevPatient) => ({
      ...prevPatient,
      [name]: value,
    }));
  };
  const handleDrugChange = (event) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedDrugs(selectedOptions);
  };
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <h1 className="text-center text-white text-3xl font-bold">מרשמים</h1>
      <div className="bg-gray-700 p-16 min-h-screen flex ">
        <div className="flex w-full justify-center space-x-8">
          <div className="flex flex-col w-1/2">
            <div className=" text-xl">
              <div className="shadow-lg rounded-lg ">
                <div className="mb-4">
                  <label className="block text-white font-semibold ">
                    שם הרופא:
                  </label>
                  <select
                    value={doctorName}
                    onChange={(e) => setDoctorName(e.target.value)}
                    className=" bg-slate-600 text-gray-100 block w-full p-2 border border-gray-700 rounded focus:ring-gray-400 focus:border-gray-400"
                  >
                    <option value="" disabled>
                      בחר רופא
                    </option>
                    {doctors.map((doctor) => (
                      <option key={doctor} value={doctor}>
                        {doctor}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-white font-semibold mb-2">
                    שם התרופה:
                  </label>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="חפש תרופה ..."
                    className="bg-slate-600 text-gray-100 m-0 block w-full p-2 border border-gray-700 rounded focus:ring-blue-500 focus:border-blue-500 mb-2"
                  />
                  <select
                    multiple
                    value={selectedDrugs}
                    onChange={handleDrugChange}
                    className=" bg-slate-600 text-gray-100 block w-full p-2 border border-gray-700 rounded focus:ring-blue-500 focus:border-blue-500 h-32 overflow-auto"
                  >
                    {Object.values(drs)
                      .filter((drug) =>
                        drug.toLowerCase().includes(searchTerm.toLowerCase())
                      )
                      .map((drug) => (
                        <option key={drug} value={drug}>
                          {drug}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-white font-semibold mb-2">
                    פירוט:
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="bg-slate-600 text-gray-100 block w-full p-2 border border-gray-700 rounded focus:ring-blue-500 focus:border-blue-500"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label className="block text-white font-semibold mb-2">
                    מינון:
                  </label>
                  <select
                    value={dosage}
                    onChange={(e) => setDosage(e.target.value)}
                    className="bg-slate-600 text-gray-100 block w-full p-2 border border-gray-700 rounded focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="" disabled>
                      ... בחר מינון
                    </option>
                    {dosages.map((dose) => (
                      <option key={dose} value={dose}>
                        {dose}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <h2 className="block text-white font-semibold mb-2">
                    פרטי המטופל:
                  </h2>
                  <label className="block text-white font-semibold mb-2">
                    שם פרטי:
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    value={patient.firstname}
                    onChange={handlePatientChange}
                    className="bg-slate-600 text-gray-100 m-0 block w-full p-2 border border-gray-700 rounded focus:ring-blue-500 focus:border-blue-500"
                  />
                  <label className="block text-white font-semibold mb-2">
                    שם משפחה:
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    value={patient.lastname}
                    onChange={handlePatientChange}
                    className="bg-slate-600 text-gray-100 m-0 block w-full p-2 border border-gray-700 rounded focus:ring-blue-500 focus:border-blue-500"
                  />
                  <label className="block text-white font-semibold mb-2">
                    תעודת זהות:
                  </label>
                  <input
                    type="text"
                    name="id"
                    value={patient.id}
                    onChange={handlePatientChange}
                    className="bg-slate-600 text-gray-100 m-0 block w-full p-2 border border-gray-700 rounded focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/2">
            <div className="p-4 text-xl">
              <div className="shadow-lg rounded-lg p-6">
                <div ref={componentRef}>
                  <DoctorDrugDescription
                    style={{ direction: 'rtl' }}
                    doctorName={doctorName}
                    drugName={selectedDrugs}
                    description={description}
                    dosage={dosage}
                    firstname={patient.firstname}
                    lastname={patient.lastname}
                    id={patient.id}
                    im={sign[doctorName]}
                  />
                </div>
                <button
                  onClick={handlePrint}
                  className="mt-4 bg-blue-500 text-white p-2 rounded"
                >
                  הדפסת מרשם
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Drugs;
