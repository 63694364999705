import React, { useState, useEffect } from 'react';
import { Avatar } from '@material-tailwind/react';
import axios from 'axios';
import backendUrl from '../../utils/config/config';
import ImageAdd from './addPatient/AddImage';
import PatientMedicalHostory from './PatientMedicalHistory';


const PatientCard = ({ patient }) => {
  const [showModal, setShowModal] = useState({});
  const [editedValue, setEditedValue] = useState('');
  const [editedField, setEditedField] = useState('');
  const [edit, setEdit] = useState(true);
  const [updatedPatient, setUpdatedPatient] = useState(patient);
  const [medical_history, setMedical_history] = useState({
    patient_id: patient.id,
    diabetes: false,
    heart_disease: false,
    congenital_heart_defect: false,
    heart_palpitations: false,
    hypertension: false,
    asthma: false,
    kidney_disease: false,
    incontinence: false,
    cancer: false,
    epilepsy: false,
    neurological_disorder: false,
    psychiatric_treatment: false,
    joint_diseases: false,
    hepatitis: false,
    aids: false,
    smoking: false,
    notes: '',
  });
  const [imageList, setImageList] = useState([]);

  const reversedIllsDic = {
    diabetes: 'סוכרת',
    heart_disease: 'מחלת לב',
    congenital_heart_defect: 'מום לב מלידה',
    heart_palpitations: 'רשרוש בלב',
    hypertension: 'יתר לחץ דם',
    asthma: 'אסטמה',
    kidney_disease: 'מחלות כליות',
    incontinence: 'ניטה לדמם',
    cancer: 'סרטן',
    epilepsy: 'אפילפסיה',
    neurological_disorder: 'מחלת עצבים',
    psychiatric_treatment: 'טיפול פסיכיאטרי',
    joint_diseases: 'מחלות פרקים',
    hepatitis: 'צהבת C/B/A',
    aids: 'איידס',
    smoking: 'עישון',
  };

  useEffect(() => {
    const fetchPatientMedical = async () => {
      try {
        const data = await axios.get(
          `${backendUrl}/medical-history/${patient.id}`
        ); // Adjust the URL as per your server
        setMedical_history(data.data);
        console.log(data.data);
      } catch (error) {}
    };

    fetchPatientMedical();
  }, []);
  useEffect(() => {
    const fetchPatientImages = async () => {
      try {
        const data = await axios.get(`${backendUrl}/images/ids/${patient.id}`);
        setImageList(data.data);
        console.log(data.data);
      } catch (error) {}
    };

    fetchPatientImages();
  }, []);

  const handleImageClick = (imgId) => {
    setShowModal((prevState) => ({
      ...prevState,
      [imgId]: true,
    }));
  };

  const handleCloseModal = (imgId) => {
    setShowModal((prevState) => ({
      ...prevState,
      [imgId]: false,
    }));
  };

  const handleEdit = (field, value) => {
    setEdit(true);
    setEditedField(field);
    setEditedValue(value);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`${backendUrl}/patients/${patient.id}`, {
        [editedField]: editedValue,
      });
      setUpdatedPatient({
        ...updatedPatient,
        [editedField]: editedValue,
      });

      console.log('Patient field updated successfully');
      console.log(updatedPatient);
      setEdit(false);
    } catch (error) {
      console.error('Error updating patient field:', error);
    }
  };

  const handleDelete = async (imageId) => {
    try {
      await axios.delete(`${backendUrl}/images/${imageId}`);

      // Remove the deleted image from the image list
      setImageList(imageList.filter((img) => img.id !== imageId));
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };
  return (
    <>
      <div className="flex p-6 gap-10 hover:font-bold border border-blue-200 rounded-lg shadow dark:bg-blue-500 dark:bg-blue-700">
        {imageList &&
          imageList.map((img, index) => (
            <div className="flex gap-4" key={index}>
              <Avatar
                className="w-32 h-36"
                src={`${backendUrl}/images/${img.id}`}
                alt={img.id}
                onClick={() => handleImageClick(img.id)}
              />

              {showModal[img.id] && (
                <div className="fixed inset-0 z-10 flex items-center justify-center bg-opacity-50">
                  <div className="relative w-full max-w-screen-lg mx-auto">
                    <div className="absolute top-4 right-4">
                      <button
                        className="text-white text-xl focus:outline-none"
                        onClick={() => handleCloseModal(img.id)}
                      >
                        &#x2715;
                      </button>
                    </div>
                    <img
                      src={`${backendUrl}/images/${img.id}`}
                      alt="avatar"
                      className="w-full max-h-screen"
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        <ImageAdd
          patient={patient}
          setImageList={setImageList}
          imageList={imageList}
        />
      </div>
      <div
        className="grid grid-flow-row-dense grid-cols-2 grid-rows-1"
        dir="rtl"
      >
        <div className="basis-1/2 border-gray-200 "></div>

        <br />
        <dl>
          <br />

          {Object.entries(updatedPatient).map(([key, value]) => (
            <div
              key={key}
              className="sm:flex border-b-4 border-gray-600 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt className="text-xl font-medium text-white">{key}</dt>
              <dd className="mt-1 text-xl   text-white sm:mt-0 sm:col-span-2">
                {key === editedField && edit ? (
                  <input
                    type="text"
                    value={editedValue}
                    onChange={(e) => setEditedValue(e.target.value)}
                    className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                ) : (
                  value
                )}

                {key === editedField && edit ? (
                  <button
                    className="float-left  bg-green-700 hover:bg-green-700 text-white font-bold rounded focus:outline-none focus:shadow-outline"
                    onClick={handleUpdate}
                  >
                    שמור
                  </button>
                ) : (
                  <button
                    className="float-left text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={() => handleEdit(key, value)}
                  >
                    ערוך
                  </button>
                )}
              </dd>
            </div>
          ))}
        </dl>

        <div className="basis-1/3 m-14 mt-20 rounded shadow-lg">
          <h1 className="text-2xl m-4 text-center font-extrabold text-white dark:text-white md:text-2xl lg:text-4xl text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            מידע רפואי
          </h1>
          <dl className="flex bg-gray-700 flex-wrap justify-center gap-8 p-4 mx-auto text-white sm:grid-cols-3 xl:grid-cols-6 dark:text-white sm:p-8">
            {Object.keys(medical_history)
              .filter((key) => medical_history[key] === 1)
              .map((ill) => (
                <div
                  key={ill}
                  className="flex flex-col items-center justify-center "
                >
                  <div className="flex p-6 hover:font-bold bg-red-100 border border-gray-500 rounded-lg shadow dark:bg-red-500 dark:bg-red-700">
                    <p className="font-semibold  mb-3 text-white dark:text-white flex ">
                      {reversedIllsDic[ill]}
                    </p>
                  </div>
                </div>
              ))}
          </dl>
          <div>
            <PatientMedicalHostory patient={patient}></PatientMedicalHostory>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientCard;
