import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/layouts/layout';
import PatientTable from './components/patients-components/patients';
import ToothChart from './components/tooth/tooth';
import AddPatientForm from './components/patients-components/PatientForm';
import HomePage from './components/homepage/homepage';
import AddPatientFormDemo from './components/patients-components/addPatient/PersonalInfo';
import Pricing from './components/pricing/Pricing';
import Drugs from './components/drugs-components/drugs';
import LoginForm from './users/Login';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ChatWindow from './components/gpt/ChatWindow';
import EventForm from './components/bigCalendar/Moment';
import MyCalendar from './components/bigCalendar/myCal';


const PrivateRoute = ({ children }) => {
  return localStorage.getItem('token') ? children : <Navigate to="/login" />;
};
ReactDOM.createRoot(document.getElementById('root')).render(
  <div dir="rtl">
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/drugs"
            element={
              <PrivateRoute>
                <Drugs />
              </PrivateRoute>
            }
          />
          <Route
            path="/patients"
            element={
              <PrivateRoute>
                <PatientTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/patient/accountment"
            element={
              <PrivateRoute>
                <Pricing />
              </PrivateRoute>
            }
          />
          <Route
            path="/patient/tooth"
            element={
              <PrivateRoute>
                <ToothChart />
              </PrivateRoute>
            }
          />
          <Route
            path="/newpatient"
            element={
              <PrivateRoute>
                <AddPatientFormDemo />
              </PrivateRoute>
            }
          />
          <Route
            path="/appointments"
            element={
              <PrivateRoute>
                {/* <EventForm /> */}
                <MyCalendar/>
              </PrivateRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <ChatWindow />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </div>
);
document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
